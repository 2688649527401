<template>
  <b-card class="program-update-tab-card">
    <!-- Table Top -->

    <b-row>
      <client-program
        v-if="isChampionPortal"
        title="Resources"
        :program="program"
      />
      <client-program
        v-if="!isChampionPortal"
        :program="program"
      />
      <b-col
        cols="6"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <!-- program resource -->
          <div v-if="isProgramResource">
            <b-button
              variant="primary"
              style="margin-left: 10px"
              @click="handleShowModal"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Add Resource</span>
            </b-button>
            <div>
              <add-resource-to-program
                :show-modal="showModal"
                :program="program"
                @hiddenModal="hiddenModal"
                @resourcesAdded="onRefreshItems"
              />
            </div>
          </div>

          <div v-else>
            <b-button
              variant="primary"
              :to="{ name: 'apps-resource-categories' }"
            >
              <feather-icon
                icon="ListIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Categories List</span>
            </b-button>
            <b-button
              v-b-toggle.add-resource-right
              variant="primary"
              style="margin-left: 10px"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Add Resource</span>
            </b-button>

            <b-sidebar
              id="add-resource-right"
              title="Add Resource"
              bg-variant="white"
              right
              backdrop
              shadow
              style="min-width: 600px !important"
              width="50%"
            >
              <div>
                <add-resource @resourceAdded="onRefreshItems" />
              </div>
            </b-sidebar>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- table program resource-->
    <program-resource-list
      v-if="isProgramResource"
      ref="resourcesList"
      :program="program"
      @edit="editResource"
    />

    <!-- table resource library -->
    <resources-list
      v-else
      ref="resourcesList"
      @edit="editResource"
    />

    <b-sidebar
      id="edit-resource-right"
      title="Edit Resource"
      bg-variant="white"
      right
      backdrop
      shadow
      style="min-width: 600px !important"
      width="50%"
    >
      <div>
        <edit-resource
          :is-program-resource="isProgramResource"
          :resource-id="resourceId"
          @resourceUpdated="onRefreshItems"
        />
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard, BSidebar, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AddResource from "../../../components/forms/resources/AddResource.vue";
import EditResource from "../../../components/forms/resources/EditResource.vue";
import ResourcesList from "./ResourcesList.vue";
import ProgramResourceList from "./ProgramResourcesList.vue";
import AddResourceToProgram from "./AddResourceToProgram.vue";
import { mapGetters } from "vuex";
import ClientProgram from '@/views/apps/admin/programs/general/ClientProgram.vue';

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    AddResource,
    EditResource,
    ResourcesList,
    BSidebar,
    ProgramResourceList,
    AddResourceToProgram,
    ClientProgram
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  props: {
    isProgramResource: {
      type: Boolean,
    },
    title: {
      type: String,
      default: ''
    },
    program: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dir: false,
      rows: [],
      resourceId: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters("app", ['isChampionPortal']),
  },
  methods: {
    onRefreshItems() {
      this.$refs.resourcesList.loadItems();
    },
    editResource(id) {
      this.resourceId = id;
    },
    handleShowModal() {
      this.showModal = true;
    },
    hiddenModal(isShow) {
      this.showModal = isShow;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  font-weight: 400 !important;
}
table.vgt-table td {
  vertical-align: middle;
}
</style>
